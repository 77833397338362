import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SortableHeader from 'components/ui/SortableHeader';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';
import commonPropTypes from 'utils/commonPropTypes';

export function SortableColumnHeader({
  sorted,
  fieldName,
  onSort,
  label,
  disabled,
}) {
  let isSorted;
  let iconAscending = false;
  if (sorted.key === fieldName) {
    isSorted = true;
    if (sorted.ascending && fieldName !== 'favorite') {
      iconAscending = true;
    }
  } else {
    isSorted = false;
  }
  return (
    <SortableHeader
      isSorted={isSorted}
      disabled={disabled}
      ascending={iconAscending}
      label={<Trans render={capitalizedTranslation} id={label} />}
      onSort={onSort(fieldName)}
    />
  );
}

SortableColumnHeader.propTypes = {
  sorted: PropTypes.shape({ key: PropTypes.string, ascending: PropTypes.bool })
    .isRequired,
  fieldName: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  label: commonPropTypes.i18nText.isRequired,
  disabled: PropTypes.bool,
};

SortableColumnHeader.defaultProps = {
  disabled: false,
};

const HeaderContainer = styled.div`
  display: inline-flex;
  min-width: 100%;
  fontsize: ${svars.fontSizeSmaller};
  color: ${svars.fontColorLighter};
  padding: 0 ${svars.spaceMedium};
`;

export const FlexCell = styled.div`
  margin: 0 ${svars.spaceNormal};
  display: flex;
  ${({ width, auto }) => `
min-width: ${width || svars.defaultColumnMinWidth}px;
width: ${
    (auto && !width && 'auto') || `${width || svars.defaultColumnMinWidth}px`
  };
flex: ${(auto && '0') || width || svars.defaultColumnMinWidth} 0 auto;
`}
  ${({ alignRight }) => (alignRight ? 'justify-content: flex-end;' : '')}
  ${({ centered }) => (centered ? 'justify-content: center;' : '')}
`;

export default function HeaderFactory(columns) {
  function Header({ onSort, sorted }) {
    return (
      <HeaderContainer>
        {columns.map(({ id, key, label, width, centered, auto, type }) => (
          <FlexCell
            width={width}
            auto={auto ? 'true' : null}
            centered={centered}
            verticalAlign="middle"
            key={`hmlhc-${key}-${id}`}
          >
            {label ? (
              <SortableColumnHeader
                onSort={onSort}
                label={label}
                sorted={sorted}
                fieldName={id}
                disabled={type === 'tag'}
              />
            ) : (
              ' '
            )}
          </FlexCell>
        ))}
      </HeaderContainer>
    );
  }
  Header.propTypes = {
    onSort: PropTypes.func.isRequired,
    sorted: PropTypes.shape(),
  };
  Header.defaultProps = { sorted: false };
  return Header;
}
